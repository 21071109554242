import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  FORMS_TABLE_NAME,
  REQUESTS_COLLECTION_NAME,
  DEFAULT_BUCKET_LIST,
} from "Constants";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { db, deleteRequest } from "Util/firebaseHelper";
import {
  Timestamp,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";

interface AllRequestsState {
  requests: CustomerRequestInfo[];
  loading: boolean;
  error: string | null;
}

const initialState: AllRequestsState = {
  requests: [],
  loading: false,
  error: null,
};

export const fetchAllRequests = createAsyncThunk(
  "requests/fetchAllRequests",
  async (formId: string, thunkAPI) => {
    const q = query(
      collection(db, FORMS_TABLE_NAME, formId, REQUESTS_COLLECTION_NAME),
      orderBy("created", "desc")
    );

    onSnapshot(q, (querySnapshot) => {
      const requests = [] as CustomerRequestInfo[];
      const requestsToDelete = [] as CustomerRequestInfo[];
      const currentDate = new Date(); // Get the current date

      querySnapshot.forEach((doc) => {
        let request = doc.data() as CustomerRequestInfo;
        if (doc.data().customerDate instanceof Timestamp) {
          request.customerDate = doc.data().customerDate.toDate().toISOString();
        }
        if (doc.data().created instanceof Timestamp) {
          request.created = doc.data().created.toDate().toISOString();
        }
        if (
          doc.data().bucketLists === undefined ||
          doc.data().bucketLists === ""
        ) {
          request.bucketLists = DEFAULT_BUCKET_LIST;
        }
        // Compare customerDate with the current date
        const diffInDays = Math.floor(
          (currentDate.getTime() - new Date(request.customerDate).getTime()) /
            (1000 * 60 * 60 * 24)
        );
        if (diffInDays > 30) {
          requestsToDelete.push(request); // Add request to requestsToDelete
        } else {
          requests.push(request);
        }
      });
      console.log("total reqests count", requests);
      thunkAPI.dispatch(requestsSlice.actions.setAllRequests(requests));
      requestsToDelete.forEach((r) => {
        thunkAPI.dispatch(
          deleteRequestAsync({
            formId: formId,
            requestId: r.requestId,
          })
        );
      });
    });
  }
);

// Async thunk to add a presale order to Firebase
export const deleteRequestAsync = createAsyncThunk(
  "requests/deleteRequestAsync",
  async ({ formId, requestId }: { formId: string; requestId: string }) => {
    await deleteRequest(formId, requestId);
    return { requestId };
  }
);

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    setAllRequests: (
      state,
      { payload }: PayloadAction<CustomerRequestInfo[]>
    ) => {
      state.requests = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<string | null>) => {
      state.error = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllRequests.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchAllRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Failed to fetch all requests";
      })
      .addCase(
        deleteRequestAsync.fulfilled,
        (state, action: PayloadAction<{ requestId: string }>) => {
          state.loading = false;
          state.error = null;
          const { requestId } = action.payload;
          state.requests.filter((r) => r.requestId !== requestId);
        }
      )
      .addCase(deleteRequestAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Failed to delete request";
      });
  },
});

export const { setAllRequests, setLoading, setError } = requestsSlice.actions;

export default requestsSlice.reducer;
