export const USERS_TABLE_NAME = "users";
export const USERNAMES_TABLE_NAME = "usernames";
export const FORMS_TABLE_NAME = "forms";
export const ORDERS_TABLE_NAME = "orders";
export const PRESALES_TABLE_NAME = "presales";
export const REQUESTS_COLLECTION_NAME = "requests";
export const ORDERS_COLLECTION_NAME = "orders";
export const SITE_URL = "flowlylink.com";
export const DASHBOARD = "dashboard";
export const MAIL_TABLE_NAME = "mail";
export const SESSIONS_TABLE_NAME = "sessions";
export const CHECKOUTS_TABLE_NAME = "checkouts";
export const EMAIL_LIST_TABLE_NAME = "emailList";
export const EMAIL_LIST_COLLECTION_NAME = "emails";
export const EMAIL_LIST_SUBSCRIBERS_COLLECTION_NAME = "subscribers";

export const REQUIRED_HELP_TEXT = "Please fill out this field.";
export const MIN_HELP_TEXT = "Value must be greater than or equal to 1.";

export const ORDER_STATUS_NOTSTARTED = "Not Started";
export const ORDER_STATUS_INPROGRESS = "In Progress";
export const ORDER_STATUS_READY = "Ready";
export const ORDER_STATUS_COMPLETED = "Completed";
export const ORDER_STATUS_CANCELED = "Canceled";

export const PRESALE_STATUS_NOTPUBLISHED = "NotPublished";
export const PRESALE_STATUS_PUBLISHED = "Published";
export const PRESALE_STATUS_ENDED = "Ended";

export const PRESALE_ANNOUCEMENT_NONE = "none";
export const PRESALE_ANNOUCEMENT_SKIPPED = "skipped";
export const PRESALE_ANNOUCEMENT_SENT = "sent";

export const PRESALE_ANNOUCEMENT_SUB_EMAIL = "#SUB_EMAIL#";

export const PRESALEORDERS_COLLECTION_NAME = "orders";

export const DEFAULT_BUCKET_LIST = "Maybe next time";

export const PAYMENT_TYPE_OTHER = "other";
export const PAYMENT_TYPE_LINK = "l";
export const PAYMENT_TYPE_INSTRUCTION = "i";
export const PAYMENT_TYPE_PAYPAL = "PayPal";
export const PAYMENT_TYPE_CASH_APP = "Cash App";
export const PAYMENT_TYPE_CREDIT_CARD = "Credit card";
export const PAYMENT_TYPE_VENMO = "Venmo";

export const PAYMENT_METHOD_PAYPAL = "PayPal";
export const PAYMENT_METHOD_CASH_APP = "Cash App";
export const PAYMENT_METHOD_CREDIT_CARD = "Credit card";
export const PAYMENT_METHOD_VENMO = "Venmo";

export const DEFAULT_COLOR_PALETTE = "como";

export const PERSONALIZATION_TYPE_CUSTOM_ONLY = "c";
export const PERSONALIZATION_TYPE_PRESET_ONLY = "p";
export const PERSONALIZATION_TYPE_BOTH = "b";

export const REVOKE_FUNCTION =
  process.env.NODE_ENV === "production"
    ? "https://squareoauthrevoke-pyssyper5a-uc.a.run.app"
    : "https://squareoauthrevoke-sewn5s44wa-uc.a.run.app";
export const OAUTH_FUNCTION =
  process.env.NODE_ENV === "production"
    ? "https://squareoauthstart-pyssyper5a-uc.a.run.app?sessionId="
    : "https://squareoauthstart-sewn5s44wa-uc.a.run.app?sessionId=";

export const CHECKOUT_FUNCTION =
  process.env.NODE_ENV === "production"
    ? "https://squarecheckoutstart-pyssyper5a-uc.a.run.app"
    : "https://squarecheckoutstart-sewn5s44wa-uc.a.run.app";
