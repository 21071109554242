import { useNavigate } from "react-router-dom";

import { Button, Typography, Box, Stack, Chip } from "@mui/material";
import { GetStarted, SignInBtn, Footer } from "Components/AllComponents";
import Logo from "Assets/logo-full.svg";
import { useSession } from "Context/userAuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import SquareIconSrc from "../Assets/square.svg";
import LandingImg1 from "Assets/landing_1.png";
import LandingImg2 from "Assets/landing_2.png";
import LandingImg3 from "Assets/landing_3.png";
import LandingImgSM from "Assets/landing_sm.png";

const Landing = () => {
  const { user } = useSession();
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const midScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Stack
      alignItems="center"
      sx={{
        pt: { xs: 2, sm: 3 },
        pb: 4,
        backgroundColor: "#fafafa",
        minHeight: "100vh",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ width: "100%", pl: { xs: 2, sm: 6 }, pr: { xs: 2, sm: 6 } }}
      >
        <Stack flex={1} alignItems="center" direction="row" spacing={1}>
          <a href="/" style={{ width: 30, height: 30 }}>
            <img src={Logo} alt="Logo" style={{ width: 30, height: 30 }} />
          </a>
          <Typography
            variant="h6"
            sx={{ userSelect: "none", fontSize: "17px", fontWeight: 700 }}
          >
            Flowlylink
          </Typography>
        </Stack>
        <Button
          variant="text"
          onClick={async () => {
            navigate("/discover");
          }}
          sx={{ height: "fit-content" }}
        >
          Inspirations
        </Button>
        {/* <Button
          variant="text"
          onClick={() => {
            window.open("https://cookiesmart.substack.com/", "_blank");
          }}
          sx={{ height: "fit-content" }}
        >
          Blog
        </Button> */}
        {user ? (
          <Button
            variant="contained"
            onClick={async () => {
              navigate("/admin/home");
            }}
            sx={{ height: "fit-content" }}
          >
            Admin
          </Button>
        ) : (
          <Box>
            <SignInBtn variant="outlined" label="Sign in" username="" />
          </Box>
        )}
      </Stack>

      <Stack alignItems="center" sx={{ mt: 6, pt: 1, pl: 2, pr: 2 }}>
        <Typography
          variant={smallScreen ? "h3" : "h2"}
          sx={{
            textAlign: "center",
          }}
        >
          Set up your
          <br />
          <Typography
            variant={smallScreen ? "h3" : "h2"}
            component="span"
            color="primary.main"
          >
            online cookie shop
          </Typography>{" "}
          in minutes
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{
            mt: 2,
            maxWidth: "600px",
            fontWeight: 500,
            textAlign: "center",
            lineHeight: "130%",
          }}
        >
          Flowlylink is your link in bio, shop, and order management system,
          making it easy to use for you and your customers.
        </Typography>
        {user ? null : (
          <Stack spacing={1} alignItems="center" sx={{ pt: 3, pb: 1 }}>
            <GetStarted size="large" />
            <Button
              variant="text"
              size="large"
              sx={{ width: "fit-content" }}
              onClick={() =>
                window.open("https://www.flowlylink.com/demo", "_blank")
              }
            >
              <Typography variant="h6">View demo</Typography>
            </Button>
          </Stack>
        )}
      </Stack>
      {smallScreen ? null : (
        <Box
          style={{
            position: "relative",
            width: "95vw",
            maxWidth: "1200px",
            height: midScreen ? "792px" : "1053px",
          }}
        >
          {/* shop */}
          <img
            src={LandingImg3}
            width="55%"
            alt="img"
            style={{
              zIndex: 10,
              position: "absolute",
              bottom: "48px",
              right: 0,
            }}
          />
          {/* presale */}
          <img
            src={LandingImg2}
            width="70%"
            alt="img"
            style={{ position: "absolute", bottom: "48px", left: 0 }}
          />
          {/* custom order */}
          <img
            src={LandingImg1}
            width="72%"
            alt="img"
            style={{
              position: "absolute",
              top: "32px",
              right: 0,
            }}
          />
        </Box>
      )}

      <Stack sx={{ width: "100%" }}>
        <Stack
          spacing={6}
          sx={{
            backgroundColor: "rgba(0,132,75,0.08)",
            width: "100%",
            alignItems: "center",
            pt: 7,
            pb: 7,
            pl: 2,
            pr: 2,
          }}
        >
          <Stack
            alignItems="center"
            sx={{
              maxWidth: "1200px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h4"
              sx={{ fontWeight: 500, mb: 4, textAlign: "center" }}
            >
              Everything you need,
              <br />
              in one link
            </Typography>
            {smallScreen ? (
              <Stack spacing={5}>
                <Stack flex={1} alignItems="center" spacing={1.5}>
                  <Typography variant="h5">Run presales</Typography>
                  <Typography>
                    With our{" "}
                    <img
                      src={SquareIconSrc}
                      alt="square"
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: 2,
                        position: "relative",
                        top: 2,
                      }}
                    />{" "}
                    <Typography component="span" style={{ fontWeight: 600 }}>
                      Square
                    </Typography>{" "}
                    integration, orders arrive paid for your convenience.
                  </Typography>
                </Stack>
                <Stack flex={1} alignItems="center" spacing={1.5}>
                  <Typography variant="h5">Take custom orders</Typography>
                  <Typography>
                    Our ready-to-use order form includes customizations to help
                    you gather details for an accurate quote.
                  </Typography>
                </Stack>
                <Stack flex={1} alignItems="center" spacing={1.5}>
                  <Typography variant="h5">Manage orders</Typography>
                  <Typography mt={1}>
                    Our order calendar is designed to help you easily track
                    orders and block dates, allowing you to spend more time on
                    cookies.
                  </Typography>
                </Stack>
              </Stack>
            ) : (
              <Stack direction="row" columnGap={6}>
                <Stack flex={1} alignItems="center" spacing={1}>
                  <Typography variant="h5">Run presales</Typography>
                  <Typography>
                    With our{" "}
                    <img
                      src={SquareIconSrc}
                      alt="square"
                      style={{
                        width: 16,
                        height: 16,
                        borderRadius: 2,
                        position: "relative",
                        top: 2,
                      }}
                    />{" "}
                    <Typography component="span" style={{ fontWeight: 600 }}>
                      Square
                    </Typography>{" "}
                    integration, orders arrive organized and paid for your
                    convenience.
                  </Typography>
                </Stack>
                <Stack flex={1} alignItems="center" spacing={1}>
                  <Typography variant="h5">Take custom orders</Typography>
                  <Typography>
                    Our ready-to-use order form includes customizations to help
                    you gather details for an accurate quote.
                  </Typography>
                </Stack>
                <Stack flex={1} alignItems="center" spacing={1}>
                  <Typography variant="h5">Manage orders</Typography>
                  <Typography mt={1}>
                    Our order calendar is designed to help you easily track
                    orders and block dates, allowing you to spend more time on
                    cookies.
                  </Typography>
                </Stack>
              </Stack>
            )}
            {user ? null : (
              <Stack sx={{ pt: 5, alignItems: "center" }}>
                <GetStarted size="large" />
                {/* <Typography variant="caption" color="text.secondary" mt={1}>
                  Currently available in the US only
                </Typography> */}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default Landing;
