import {
  MAIL_TABLE_NAME,
  FORMS_TABLE_NAME,
  REQUESTS_COLLECTION_NAME,
  USERNAMES_TABLE_NAME,
  USERS_TABLE_NAME,
  ORDERS_TABLE_NAME,
  ORDERS_COLLECTION_NAME,
  ORDER_STATUS_NOTSTARTED,
  ORDER_STATUS_INPROGRESS,
  ORDER_STATUS_READY,
  DEFAULT_BUCKET_LIST,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CANCELED,
  PRESALES_TABLE_NAME,
  PRESALEORDERS_COLLECTION_NAME,
  PERSONALIZATION_TYPE_CUSTOM_ONLY,
  SESSIONS_TABLE_NAME,
  CHECKOUTS_TABLE_NAME,
  EMAIL_LIST_TABLE_NAME,
  EMAIL_LIST_SUBSCRIBERS_COLLECTION_NAME,
  EMAIL_LIST_COLLECTION_NAME,
} from "Constants";

import { REQUEST_EMAIL_TEMPLATE } from "EmailTemplates/CustomOrder/RequestEmail";
import { CUSTOM_ORDER_EMAIL_TEMPLATE } from "EmailTemplates/CustomOrder/OrderConfirmation";
import { PRESALE_ORDER_EMAIL_TEMPLATE } from "EmailTemplates/Presale/PresaleOrderConfirm";
import { PRESALE_ORDER_SELLER_EMAIL_TEMPLATE } from "EmailTemplates/Presale/PresaleOrderSeller";
import { PRESALE_ORDER_INFO_EMAIL_TEMPLATE } from "EmailTemplates/Presale/PresaleOrderInfo";
import { PRESALE_ORDER_CANCELLATION_EMAIL_TEMPLATE } from "EmailTemplates/Presale/PresaleOrderCancellation";

import { initializeApp, getApp, FirebaseError } from "firebase/app";
import {
  getFirestore,
  collection,
  getDoc,
  writeBatch,
  doc,
  setDoc,
  runTransaction,
  arrayUnion,
  Timestamp,
  addDoc,
  deleteDoc,
  getDocs,
  Firestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { BusinessInfo } from "Model/BusinessInfo";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import { customAlphabet, nanoid } from "nanoid";
import { firebaseDevConfig, firebaseProdConfig } from "./config";
import { Profile } from "Model/Profile";
import { PresaleInfo } from "Model/PresaleInfo";
import _ from "lodash";
import { OrderInfo } from "Model/OrderInfo";
import { OrdersStatus } from "Model/OrdersStatus";
import { PresaleOrder } from "Model/PresaleOrder";
import { Checkout } from "Model/Checkout";

const nanoid6 = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 6);

// https://stackoverflow.com/questions/46135993/how-to-change-the-app-name-for-firebase-authentication-what-the-user-sees
// how to change authorization page domain name
const firebaseApp = initializeApp(
  process.env.NODE_ENV === "production" ? firebaseProdConfig : firebaseDevConfig
);

const app = getApp();
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "127.0.0.1", 5001);

const db = getFirestore();
// connectFirestoreEmulator(db, "127.0.0.1", 8080);
const auth = getAuth();
const storage = getStorage();
const googleProvider = new GoogleAuthProvider().setCustomParameters({
  // this custom parameter will solve the issue of auto log in. You can now select the account though you may not need to enter password everytime.
  prompt: "select_account",
});

const login = async () => {
  return await signInWithPopup(auth, googleProvider);
};

const signUp = async (email: string, password: string) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

const logOut = async () => {
  return await signOut(auth);
};

const generateFormId = async (uid: string) => {
  while (true) {
    const formId = nanoid6();
    const docRef = doc(db, FORMS_TABLE_NAME, formId);
    var result = await getDoc(docRef);
    if (result.exists()) {
      continue;
    } else {
      setDoc(doc(db, FORMS_TABLE_NAME, formId), { uid: uid });
      return formId;
    }
  }
};

const getSellerProfile = async (uid: string) => {
  const user = await getDoc(doc(db, USERS_TABLE_NAME, uid));
  const userData = user.data();
  return userData;
};

const publishProfile = async (profile: Profile, uid: string) => {
  const userDocRef = doc(db, USERS_TABLE_NAME, uid);
  const usernameDocRef = doc(
    db,
    USERNAMES_TABLE_NAME,
    profile.username.toLowerCase()
  );
  const oldUserDocRef = doc(db, USERS_TABLE_NAME, uid);

  await runTransaction(db, async (transaction) => {
    const oldUserSnapshot = await transaction.get(oldUserDocRef);
    const oldUserData = oldUserSnapshot.data();
    var oldUsername = "";
    if (oldUserData) {
      oldUsername = oldUserData.username;
    }

    const userSnapshot = await transaction.get(userDocRef);
    const userData = userSnapshot.data();
    if (userData) {
      const forms = userData.forms;
      if (forms) {
        forms.forEach(function (f: string) {
          const formRef = doc(db, FORMS_TABLE_NAME, f);
          transaction.set(
            formRef,
            {
              ...profile,
              updated: Timestamp.now().toDate().toISOString(),
            },
            { merge: true }
          );
        });
      }
    }
    transaction.set(
      userDocRef,
      {
        ...profile,
        created: Timestamp.now().toDate().toISOString(),
        updatedProfile: Timestamp.now().toDate().toISOString(),
      },
      { merge: true }
    );
    transaction.set(
      usernameDocRef,
      {
        ...profile,
        updated: Timestamp.now().toDate().toISOString(),
      },
      { merge: true }
    );

    if (oldUsername !== "" && oldUsername !== profile.username) {
      transaction.delete(doc(db, USERNAMES_TABLE_NAME, oldUsername));
    }
  });

  return true;
};

const publishBusinessInfo = async (
  sellerProfile: Profile,
  form: BusinessInfo,
  uid: string
) => {
  if (form.id === "") {
    form.id = await generateFormId(uid);
    // first time publishing form
    const docRef = doc(db, FORMS_TABLE_NAME, form.id);
    console.log("new form id ", form.id);
    // Create new request for formId and set total request to 1
    setDoc(
      docRef,
      {
        ...form,
        ...sellerProfile,
        totalRequests: 0,
        created: Timestamp.now().toDate().toISOString(),
        updated: Timestamp.now().toDate().toISOString(),
      },
      { merge: true }
    );
    setDoc(
      doc(db, USERS_TABLE_NAME, uid),
      {
        created: Timestamp.now().toDate().toISOString(),
        updated: Timestamp.now().toDate().toISOString(),
        forms: arrayUnion(form.id),
      },
      { merge: true }
    );
    setDoc(
      doc(db, USERNAMES_TABLE_NAME, sellerProfile.username),
      {
        created: Timestamp.now().toDate().toISOString(),
        updated: Timestamp.now().toDate().toISOString(),
        forms: arrayUnion(form.id),
      },
      { merge: true }
    );
  } else {
    console.log("form idddd", form.id);

    const docRef = doc(db, FORMS_TABLE_NAME, form.id);
    setDoc(
      docRef,
      {
        ...form,
        ...sellerProfile,
        updated: Timestamp.now().toDate().toISOString(),
      },
      { merge: true }
    );
    setDoc(
      doc(db, USERS_TABLE_NAME, uid),
      {
        updated: Timestamp.now().toDate().toISOString(),
        forms: arrayUnion(form.id),
      },
      { merge: true }
    );
  }

  return true;
};

const submitCustomerRequestInfoTransaction = async (
  form: CustomerRequestInfo,
  businessInfo: BusinessInfo
) => {
  try {
    const totalRequestCount = await runTransaction(db, async (transaction) => {
      const docRef = doc(db, FORMS_TABLE_NAME, businessInfo.id);
      let requestsDoc = await transaction.get(docRef);
      let totalRequestCount = 0;
      if (!requestsDoc.exists()) {
        // Create new request for formId and set total request to 1
        transaction.set(
          docRef,
          {
            totalRequests: 1,
            created: Timestamp.now().toDate().toISOString(),
            updated: Timestamp.now().toDate().toISOString(),
          },
          { merge: true }
        );
        totalRequestCount = 1;
      } else {
        const newTotalRequests = requestsDoc.data().totalRequests + 1;
        transaction.update(docRef, {
          totalRequests: newTotalRequests,
          updated: Timestamp.now().toDate().toISOString(),
        });
        totalRequestCount = newTotalRequests;
      }
      const requestRef = doc(
        db,
        FORMS_TABLE_NAME,
        businessInfo.id,
        REQUESTS_COLLECTION_NAME,
        totalRequestCount.toString()
      );
      transaction.set(requestRef, {
        ...form,
        requestId: totalRequestCount.toString(),
        created: Timestamp.now().toDate().toISOString(),
      });

      const mailRefToSeller = doc(collection(db, MAIL_TABLE_NAME));
      transaction.set(mailRefToSeller, {
        to: [businessInfo.sellerEmail],
        message: {
          subject: `Order Request #${totalRequestCount}${
            form.bucketLists !== DEFAULT_BUCKET_LIST
              ? " (Bucket List Order)"
              : ""
          }`,
          html: REQUEST_EMAIL_TEMPLATE(
            totalRequestCount,
            form,
            false,
            businessInfo
          ),
        },
      });
      const mailRefToCustomer = doc(collection(db, MAIL_TABLE_NAME));
      transaction.set(mailRefToCustomer, {
        to: [form.email],
        message: {
          subject: `You submitted a new request`,
          html: REQUEST_EMAIL_TEMPLATE(
            totalRequestCount,
            form,
            true,
            businessInfo
          ),
        },
      });

      return totalRequestCount;
    });
    console.log(
      "Transaction successfully committed! Total request count",
      totalRequestCount
    );
  } catch (e) {
    console.log("Transaction failed: ", e);
  }

  // const docRef = await addDoc(
  //   collection(db, REQUESTS_TABLE_NAME, formId, REQUESTS_COLLECTION_NAME),
  //   {
  //     ...form,
  //     created: Timestamp.now().toDate().toISOString(),
  //   }
  // );
  // console.log("Generated doc id", docRef.id);
};

const submitNewOrderTransaction = async (
  uid: string,
  formId: string,
  form: OrderInfo,
  fromRequest: boolean,
  sellerProfile: Profile
) => {
  try {
    console.log("order", uid, form);

    const totalCount = await runTransaction(db, async (transaction) => {
      const docRef = doc(db, ORDERS_TABLE_NAME, uid);
      let ordersRef = await transaction.get(docRef);
      let totalOrdersCount = 0;
      if (!ordersRef.exists()) {
        // Create new request for formId and set total request to 1
        transaction.set(
          docRef,
          {
            totalOrders: 1,
            ordersMap: {
              [ORDER_STATUS_NOTSTARTED]: ["1"],
              [ORDER_STATUS_INPROGRESS]: [],
              // [ORDER_STATUS_TOBAKE]: [],
              // [ORDER_STATUS_TODECORATE]: [],
              [ORDER_STATUS_READY]: [],
              [ORDER_STATUS_COMPLETED]: [],
              [ORDER_STATUS_CANCELED]: [],
            },
            created: Timestamp.now().toDate().toISOString(),
            updated: Timestamp.now().toDate().toISOString(),
          },
          { merge: true }
        );
        totalOrdersCount = 1;
      } else {
        const newTotalOrders = ordersRef.data().totalOrders + 1;
        transaction.set(
          docRef,
          {
            totalOrders: newTotalOrders,
            ordersMap: {
              ...ordersRef.data().ordersMap,
              [ORDER_STATUS_NOTSTARTED]: [
                ...ordersRef.data().ordersMap[ORDER_STATUS_NOTSTARTED],
                newTotalOrders.toString(),
              ],
            },
            updated: Timestamp.now().toDate().toISOString(),
          },
          { merge: true }
        );
        totalOrdersCount = newTotalOrders;
      }
      if (fromRequest) {
        transaction.set(
          doc(
            db,
            FORMS_TABLE_NAME,
            formId,
            REQUESTS_COLLECTION_NAME,
            form.requestId
          ),
          { orderId: totalOrdersCount.toString() },
          { merge: true }
        );
      }
      const orderRef = doc(
        db,
        ORDERS_TABLE_NAME,
        uid,
        ORDERS_COLLECTION_NAME,
        totalOrdersCount.toString()
      );
      transaction.set(
        orderRef,
        {
          ...form,
          requestCreated: form.created,
          formId: formId,
          requestId: form.requestId,
          orderId: totalOrdersCount.toString(),
          orderCreated: Timestamp.now().toDate().toISOString(),
          created: Timestamp.now().toDate().toISOString(),
          status: ORDER_STATUS_NOTSTARTED,
        },
        { merge: true }
      );
      const mailRefToCustomer = doc(collection(db, MAIL_TABLE_NAME));
      transaction.set(mailRefToCustomer, {
        to: [form.email],
        message: {
          subject: `Custom order confirmed`,
          html: CUSTOM_ORDER_EMAIL_TEMPLATE(form, sellerProfile),
        },
      });

      return totalOrdersCount;
    });
    console.log(
      "Transaction successfully committed! Total order count",
      totalCount
    );
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
};

const updateOrderInfo = async (uid: string, orderInfo: OrderInfo) => {
  const orderRef = doc(
    db,
    ORDERS_TABLE_NAME,
    uid,
    ORDERS_COLLECTION_NAME,
    orderInfo.orderId
  );
  await setDoc(
    orderRef,
    {
      ...orderInfo,
      updated: Timestamp.now().toDate().toISOString(),
    },
    { merge: true }
  );
};

const updateOrderStatus = async (
  uid: string,
  orderId: string,
  orderStatus: string,
  ordersStatus: OrdersStatus
) => {
  console.log("order", uid, ordersStatus);
  const batch = writeBatch(db);
  // Update orders status list and count
  const docRef = doc(db, ORDERS_TABLE_NAME, uid);
  batch.set(
    docRef,
    {
      ...ordersStatus,
      updated: Timestamp.now().toDate().toISOString(),
    },
    { merge: true }
  );

  // Update individual order status
  const orderRef = doc(
    db,
    ORDERS_TABLE_NAME,
    uid,
    ORDERS_COLLECTION_NAME,
    orderId
  );
  batch.set(
    orderRef,
    {
      status: orderStatus,
    },
    { merge: true }
  );

  // Commit the batch
  await batch.commit();
};

const getBusinessInfoFromUser = async (uid: string) => {
  const user = await getDoc(doc(db, USERS_TABLE_NAME, uid));
  const userData = user.data();
  if (userData) {
    const forms = userData.forms;
    if (forms && forms.length > 0) {
      const form = await getDoc(doc(db, FORMS_TABLE_NAME, forms[0]));
      return form;
    }
  }
  return null;
};

const readBusinessInfo = async (formId: string) => {
  const form = await getDoc(doc(db, FORMS_TABLE_NAME, formId));
  return form;
  //     return form;
  // const docRef = doc(db, USERS_TABLE_NAME, uid);

  // const user = await getDoc(docRef);
  // const userData = user.data();
  // if (userData) {
  //   const forms = userData.forms;
  //   if (forms.length > 0) {
  //     const form = await getDoc(doc(db, FORMS_TABLE_NAME, forms[0]));
  //     return form;
  //   }
  // }
  // return null;
};

const readSellerInfo = async (linkId: string) => {
  const form = await getDoc(doc(db, USERNAMES_TABLE_NAME, linkId));
  return form;
};

const checkUsername = async (username: string) => {
  const docRef = doc(db, USERNAMES_TABLE_NAME, username.toLowerCase());
  return await getDoc(docRef);
};

const bakeLinkSignIn = async (uid: string, username: string) => {
  username = username.toLowerCase();
  if (_.isEmpty(username)) {
    return;
  }
  const userProfile = await getDoc(doc(db, USERS_TABLE_NAME, uid));
  if (
    userProfile.exists() &&
    userProfile.data().username &&
    userProfile.data().username.length !== 0
  ) {
    return;
  }
  await setDoc(
    doc(db, USERNAMES_TABLE_NAME, username),
    {
      created: Timestamp.now().toDate().toISOString(),
    },
    { merge: true }
  );
  await setDoc(doc(db, USERS_TABLE_NAME, uid), { username }, { merge: true });
};

const uploadImage = async (image: File, imageName: string) => {
  const storageRef = ref(storage, `/images/${imageName}`);

  // 'file' comes from the Blob or File API
  const uploadTask = await uploadBytes(storageRef, image);
  return await getDownloadURL(uploadTask.ref);
};

const uploadDesignInspiration = async (
  image: File,
  formId: string,
  imageId: string,
  imageName: string
) => {
  const storageRef = ref(
    storage,
    `/DesignInspirations/${formId}/${imageId}_${imageName}`
  );

  // 'file' comes from the Blob or File API
  const uploadTask = await uploadBytes(storageRef, image);
  return await getDownloadURL(uploadTask.ref);
};

const uploadImageGallery = async (
  image: File,
  uid: string,
  imageId: string,
  imageName: string
) => {
  const storageRef = ref(
    storage,
    `/ImageGallery/${uid}/${imageId}_${imageName}`
  );

  // 'file' comes from the Blob or File API
  const uploadTask = await uploadBytes(storageRef, image);
  return await getDownloadURL(uploadTask.ref);
};

const uploadPresaleItemImage = async (
  image: File,
  uid: string,
  presaleId: string,
  presaleItemId: string
) => {
  const storageRef = ref(
    storage,
    `/presales/${uid}/${presaleId}/${presaleItemId}/${nanoid6()}`
  );

  // 'file' comes from the Blob or File API
  const uploadTask = await uploadBytes(storageRef, image);
  return await getDownloadURL(uploadTask.ref);
};

const deleteDesignInspiration = async (imagePath: string) => {
  const storageRef = ref(storage, imagePath);
  // Delete the file
  await deleteObject(storageRef);
};

const fetchPresales = async (presaleList: string[]) => {
  const promises = presaleList.map((presale) =>
    getDoc(doc(db, PRESALES_TABLE_NAME, presale))
  );
  const results = await Promise.all(promises);
  const myArray = results.map((result) => {
    const r = result.data() as PresaleInfo;
    const updatedPresaleItems = r.presaleItems.map((item) => ({
      ...item,
      flavors: item.flavors || [],
      colors: item.colors || [],
      personalizationType:
        item.personalizationType || PERSONALIZATION_TYPE_CUSTOM_ONLY,
      personalizationOptions: item.personalizationOptions || [],
    }));
    return {
      ...r,
      presaleItems: updatedPresaleItems,
    };
  });
  return myArray;
};

const fetchPresale = async (presaleId: string) => {
  const result = await getDoc(doc(db, PRESALES_TABLE_NAME, presaleId));
  return result;
};

const createPresale = async (newData: PresaleInfo) => {
  const presaleCollectionRef = collection(db, PRESALES_TABLE_NAME);
  const newPresaleRef = doc(presaleCollectionRef);
  const updatedNewData = {
    ...newData,
    presaleId: newPresaleRef.id,
    createdAt: new Date(Date.now()).toISOString(),
  };
  await setDoc(newPresaleRef, updatedNewData);

  return newPresaleRef.id;
};

const updatePresale = async (presaleInfo: PresaleInfo) => {
  const presaleRef = doc(db, PRESALES_TABLE_NAME, presaleInfo.presaleId);
  await setDoc(
    presaleRef,
    { ...presaleInfo, updatedAt: new Date(Date.now()).toISOString() },
    { merge: true }
  );
};

const deletePresale = async (presaleId: string) => {
  const presaleRef = doc(db, PRESALES_TABLE_NAME, presaleId);
  const ordersCollectionRef = collection(
    db,
    PRESALES_TABLE_NAME,
    presaleId,
    PRESALEORDERS_COLLECTION_NAME
  );

  const batch = writeBatch(db);
  // Delete the presale
  batch.delete(presaleRef);

  // Query orders with matching presaleId and delete them
  const querySnapshot = await getDocs(ordersCollectionRef);
  querySnapshot.forEach((doc) => {
    batch.delete(doc.ref);
  });

  // Commit the batched write
  await batch.commit();
};

const createPresaleOrder = async (
  newData: PresaleOrder,
  sellerProfile: Profile,
  presale: PresaleInfo,
  sendMail: boolean
) => {
  const presaleOrderRef = doc(
    db,
    PRESALES_TABLE_NAME,
    newData.presaleId,
    PRESALEORDERS_COLLECTION_NAME,
    newData.orderId
  );
  await setDoc(presaleOrderRef, newData);

  if (sendMail) {
    const mailRefToSeller = doc(collection(db, MAIL_TABLE_NAME));
    setDoc(mailRefToSeller, {
      to: [sellerProfile.sellerEmail],
      message: {
        subject: `New presale order`,
        html: PRESALE_ORDER_SELLER_EMAIL_TEMPLATE(
          newData,
          sellerProfile,
          presale
        ),
      },
    });

    const mailRefToCustomer = doc(collection(db, MAIL_TABLE_NAME));
    setDoc(mailRefToCustomer, {
      to: [newData.email],
      message: {
        subject: `Thanks for your order`,
        html: PRESALE_ORDER_EMAIL_TEMPLATE(newData, sellerProfile),
      },
    });
  }

  return newData.orderId;
};

const updatePresaleOrder = async (newData: PresaleOrder) => {
  const presaleOrderRef = doc(
    db,
    PRESALES_TABLE_NAME,
    newData.presaleId,
    PRESALEORDERS_COLLECTION_NAME,
    newData.orderId
  );

  return await setDoc(presaleOrderRef, newData, { merge: true });
};

const deletePresaleCustomerOrder = async (
  presaleId: string,
  orderId: string
) => {
  const presaleOrderRef = doc(
    db,
    PRESALES_TABLE_NAME,
    presaleId,
    PRESALEORDERS_COLLECTION_NAME,
    orderId
  );
  await deleteDoc(presaleOrderRef);
};

const readPresaleOrders = async (presaleIds: string[]) => {
  // Create an array of promises for fetching orders
  const promises = presaleIds.map(async (presaleId) => {
    const ordersCollection = collection(
      db,
      PRESALES_TABLE_NAME,
      presaleId,
      PRESALEORDERS_COLLECTION_NAME
    );
    const querySnapshot = await getDocs(ordersCollection);
    const presaleOrders: PresaleOrder[] = [];

    querySnapshot.forEach((doc) => {
      presaleOrders.push(doc.data() as PresaleOrder);
    });

    return { presaleId, orders: presaleOrders };
  });

  // Wait for all promises to resolve
  const results = await Promise.all(promises);

  // Convert results to a dictionary of orders
  const orders: Record<string, PresaleOrder[]> = {};
  results.forEach((result) => {
    orders[result.presaleId] = result.orders;
  });

  return orders;
};

const deleteRequest = async (formId: string, requestId: string) => {
  const requestRef = doc(
    db,
    FORMS_TABLE_NAME,
    formId,
    REQUESTS_COLLECTION_NAME,
    requestId
  );
  await deleteDoc(requestRef);
};

const deleteOrder = async (uid: string, orderId: string) => {
  const orderRef = doc(
    db,
    ORDERS_TABLE_NAME,
    uid,
    ORDERS_COLLECTION_NAME,
    orderId
  );
  await deleteDoc(orderRef);
};
const sendCancellationEmail = async (
  order: PresaleOrder,
  sellerProfile: Profile,
  subject: string
) => {
  const mailRef = doc(collection(db, MAIL_TABLE_NAME));
  await setDoc(mailRef, {
    to: [order.email],
    message: {
      subject: subject,
      html: PRESALE_ORDER_CANCELLATION_EMAIL_TEMPLATE(order, sellerProfile),
    },
  });
  // send a copy to seller
  const mailRefToSeller = doc(collection(db, MAIL_TABLE_NAME));
  await setDoc(mailRefToSeller, {
    to: [sellerProfile.sellerEmail],
    message: {
      subject: subject,
      html: PRESALE_ORDER_CANCELLATION_EMAIL_TEMPLATE(order, sellerProfile),
    },
  });
};

const sendNotificationEmail = async (
  order: PresaleOrder,
  sellerProfile: Profile,
  subject: string,
  emailHeading: string,
  emailPrimary: string,
  emailBody: string,
  testEmail: boolean,
  emailTopic: string,
  ccSeller: boolean
) => {
  const mailRef = doc(collection(db, MAIL_TABLE_NAME));
  await setDoc(mailRef, {
    to: [testEmail ? sellerProfile.sellerEmail : order.email],
    message: {
      subject: subject,
      html: PRESALE_ORDER_INFO_EMAIL_TEMPLATE(
        order,
        sellerProfile,
        emailHeading,
        emailPrimary,
        emailBody,
        testEmail,
        emailTopic
      ),
    },
  });
  if (ccSeller) {
    const mailRefToSeller = doc(collection(db, MAIL_TABLE_NAME));
    await setDoc(mailRefToSeller, {
      to: [sellerProfile.sellerEmail],
      message: {
        subject: subject,
        html: PRESALE_ORDER_INFO_EMAIL_TEMPLATE(
          order,
          sellerProfile,
          emailHeading,
          emailPrimary,
          emailBody,
          testEmail,
          emailTopic
        ),
      },
    });
  }
};

const startSquareOAuth = async (username: string) => {
  if (!auth.currentUser) {
    return "";
  }
  const sessionId = nanoid();
  const state = nanoid();

  const sessionDoc = doc(collection(db, SESSIONS_TABLE_NAME), sessionId);
  const date = new Date(Date.now() + 3 * 60 * 1000);
  const expiresAt = date;
  try {
    await setDoc(sessionDoc, {
      sessionId: sessionId,
      state: state,
      uid: auth.currentUser.uid,
      username,
      expiresAt,
    });
    return { sessionId, state };
  } catch (e) {
    console.log("Error saving state to Firestore:", e);
    return "";
  }
};

const startSquareCheckout = async (
  orderId: string,
  sellerId: string,
  presaleId: string,
  sellerEmail: string,
  customerEmail: string
) => {
  const checkoutRef = doc(collection(db, CHECKOUTS_TABLE_NAME));
  await setDoc(checkoutRef, {
    orderId: orderId,
    sellerId: sellerId,
    presaleId: presaleId,
    checkoutId: checkoutRef.id,
    sellerEmail: sellerEmail,
    customerEmail: customerEmail,
    paid: false,
    createdAt: new Date(Date.now()).toISOString(),
    updatedAt: new Date(Date.now()).toISOString(),
  } as Checkout);
  return checkoutRef.id;
};

const joinEmailList = async (email: string, sellerId: string) => {
  try {
    const emailListRef = doc(
      db,
      EMAIL_LIST_TABLE_NAME,
      sellerId,
      EMAIL_LIST_SUBSCRIBERS_COLLECTION_NAME,
      email
    );
    await setDoc(
      emailListRef,
      {
        email: email,
        joinedAt: new Date(Date.now()).toISOString(),
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    if (error instanceof FirebaseError) {
      if (error.code === "permission-denied") {
        return true;
      }
    }
    return false;
  }
};

const deleteEmailFromList = async (email: string, sellerId: string) => {
  try {
    const emailListRef = doc(
      db,
      EMAIL_LIST_TABLE_NAME,
      sellerId,
      EMAIL_LIST_SUBSCRIBERS_COLLECTION_NAME,
      email
    );
    await deleteDoc(emailListRef);
    return true;
  } catch (error) {
    console.error("Error deleting email from list: ", error);
    return false;
  }
};

const fetchSubscribersList = async (sellerId: string) => {
  try {
    const emailListRef = collection(
      db,
      EMAIL_LIST_TABLE_NAME,
      sellerId,
      EMAIL_LIST_SUBSCRIBERS_COLLECTION_NAME
    );

    const snapshot = await getDocs(emailListRef);
    const subscribers = snapshot.docs.map((doc) => doc.id);

    return subscribers;
  } catch (error) {
    if (error instanceof FirebaseError) {
      if (error.code === "permission-denied") {
        console.error("Permission denied:", error);
      }
    }
    console.error("Error fetching subscribers list:", error);
    return [];
  }
};

const sendEmailToSubscribers = async (
  uid: string,
  subject: string,
  html: string
) => {
  try {
    const dateNow = new Date(Date.now()).toISOString();
    const emailListRef = doc(
      db,
      EMAIL_LIST_TABLE_NAME,
      uid,
      EMAIL_LIST_COLLECTION_NAME,
      dateNow
    );
    await setDoc(
      emailListRef,
      {
        subject: subject,
        html: html,
        createdAt: new Date(Date.now()).toISOString(),
        updatedAt: new Date(Date.now()).toISOString(),
      },
      { merge: true }
    );
    return true;
  } catch (error) {
    console.error("Error sending email: ", error);
    return false;
  }
};

const sendTestEmailToSubscribers = async (
  email: string,
  subject: string,
  html: string
) => {
  const mailRef = doc(collection(db, MAIL_TABLE_NAME));
  await setDoc(mailRef, {
    to: [email],
    message: {
      subject: subject,
      html: html,
    },
  });
};

export {
  db,
  auth,
  storage,
  login,
  signUp,
  logOut,
  checkUsername,
  publishBusinessInfo,
  submitCustomerRequestInfoTransaction,
  readBusinessInfo,
  getBusinessInfoFromUser,
  generateFormId,
  payments,
  publishProfile,
  getSellerProfile,
  uploadImage,
  uploadDesignInspiration,
  uploadImageGallery,
  deleteDesignInspiration,
  bakeLinkSignIn,
  readSellerInfo,
  submitNewOrderTransaction,
  updateOrderStatus,
  updateOrderInfo,
  fetchPresales,
  fetchPresale,
  createPresale,
  updatePresale,
  deletePresale,
  uploadPresaleItemImage,
  createPresaleOrder,
  updatePresaleOrder,
  deletePresaleCustomerOrder,
  readPresaleOrders,
  deleteRequest,
  deleteOrder,
  sendCancellationEmail,
  sendNotificationEmail,
  startSquareOAuth,
  startSquareCheckout,
  joinEmailList,
  deleteEmailFromList,
  sendEmailToSubscribers,
  fetchSubscribersList,
  sendTestEmailToSubscribers,
};
