import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from "Context/userAuthContext";
import {
  formatDateFromString,
  getMonthFromString,
  getDateFromString,
} from "Util/dateUtil";
import { OrderInfo, orderInfoValidationSchema } from "Model/OrderInfo";
import _ from "lodash";
import {
  ORDER_STATUS_NOTSTARTED,
  // ORDER_STATUS_TOBAKE,
  // ORDER_STATUS_TODECORATE,
  ORDER_STATUS_INPROGRESS,
  ORDER_STATUS_READY,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_CANCELED,
} from "Constants";
import { updateOrderStatus } from "Util/firebaseHelper";
import { Unit } from "Model/CustomerRequestInfo";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  ordersSelector,
  ordersStatusSelector,
  selectedOrderSelector,
} from "Redux/selectors";
import { useFormik } from "formik";
import { select } from "Redux/actionCreators";
import { updateSelectedOrderAsync } from "Redux/Reducers/selectedOrder";
import { updateOrderStatusAsync } from "Redux/Reducers/ordersStatus";

// ui related
import { deepPurple } from "@mui/material/colors";
import {
  Box,
  Button,
  Typography,
  Divider,
  Stack,
  ListItem,
  Dialog,
  DialogContent,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
  TextField,
  Card,
  LinearProgress,
} from "@mui/material";
import { NewOrderInfo } from "Pages/BusinessView/AllOrders/Components/NewOrderInfo";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { InfoBox } from "Components/Containers/InfoBox";
import { Header } from "Components/Header";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";

// icons
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LabeledText } from "Components/LabeledText";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import { RequestDetails } from "./RequestDetails";
import { OverviewGrid } from "Components/BusinessView/CustomOrder/OverviewGrid";
import { ContactInfo } from "Components/BusinessView/CustomOrder/ContactInfo";
import { TrackPayment } from "Components/BusinessView/CustomOrder/TrackPayment";
import { ShipTo } from "Components/BusinessView/CustomOrder/ShipTo";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

type ViewOrderProps = {
  view: "calendar" | "kanban";
  orderInfo: OrderInfo;
};

export const ViewOrder = ({ view, orderInfo }: ViewOrderProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  let { orderId } = useParams<"orderId">();
  const { user, sellerProfile } = useSession();
  const dispatch = useAppDispatch();
  const allOrders = useAppSelector(ordersSelector);
  const getOrderFromId = (orderId: string) => {
    return allOrders.find((i) => i.orderId === orderId);
  };
  const [openOrderInfo, setOpenOrderInfo] = useState(
    orderId !== undefined && orderId === orderInfo.orderId
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (orderId !== undefined) {
      if (orderId === orderInfo.orderId) {
        dispatch(select(orderInfo));
      } else if (getOrderFromId(orderId)?.status === ORDER_STATUS_COMPLETED) {
        navigate(`/admin/completed-orders/${orderId}`);
      } else if (getOrderFromId(orderId)?.status === ORDER_STATUS_CANCELED) {
        navigate(`/admin/canceled-orders/${orderId}`);
      }
    }
  });

  const handleOpenOrderInfo = () => {
    dispatch(select(orderInfo));
    navigate(`/admin/orders/${orderInfo.orderId}`);
    setOpenOrderInfo(true);
  };
  const handleCloseOrderInfo = () => {
    formik.resetForm();
    setNewOrderInfo(false);
    setOpenOrderInfo(false);
    navigate(`/admin/orders`);
  };

  const currentOrder = useAppSelector(selectedOrderSelector);
  const ordersStatus = useAppSelector(ordersStatusSelector);
  const [notes, setNotes] = useState(orderInfo.notes);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: currentOrder,
    validationSchema: orderInfoValidationSchema(
      sellerProfile.payments.length > 0
    ),
    onSubmit: async (values, { resetForm }) => {
      if (user?.uid) {
        if (formik.dirty) {
          dispatch(
            updateSelectedOrderAsync({ userId: user?.uid, orderInfo: values })
          );
        }
        setNewOrderInfo(false);
      }
      //alert(JSON.stringify(values, null, 2));
    },
  });

  const moveOrder = (
    from: string,
    to: string,
    fromIndex: number,
    toIndex: number
  ) => {
    console.log(
      "Move order from ",
      currentOrder.orderId,
      from,
      to,
      fromIndex,
      toIndex,
      ordersStatus
    );

    let newOrdersStatus = _.cloneDeep(ordersStatus);
    newOrdersStatus.ordersMap[from].splice(fromIndex, 1);
    newOrdersStatus.ordersMap[to].splice(toIndex, 0, currentOrder.orderId);

    console.log(
      "Moved order from ",
      from,
      to,
      fromIndex,
      toIndex,
      newOrdersStatus
    );
    if (user) {
      dispatch(
        updateOrderStatusAsync({
          userId: user.uid,
          orderInfo: currentOrder,
          orderStatus: to,
          ordersStatus: newOrdersStatus,
        })
      );
    }
    return newOrdersStatus;
  };

  const [toggleAlignment, setToggleAlignment] = useState(orderInfo.status);

  useEffect(() => {
    setToggleAlignment(orderInfo.status);
  }, [orderInfo]);

  const handleProgressChange = (
    event: React.MouseEvent<HTMLElement>,
    newOrderStatus: string
  ) => {
    console.log("orere", ordersStatus.ordersMap, currentOrder.orderId);
    if (newOrderStatus === null) {
      return;
    }

    const newOrdersStatus = moveOrder(
      currentOrder.status,
      newOrderStatus,
      ordersStatus.ordersMap[currentOrder.status].indexOf(
        currentOrder.orderId
      ) || 0,
      ordersStatus.ordersMap[newOrderStatus].length || 0
    );
    setToggleAlignment(newOrderStatus);
    if (user) {
      updateOrderStatus(
        user?.uid,
        currentOrder.orderId,
        newOrderStatus,
        newOrdersStatus
      );
      dispatch(
        updateSelectedOrderAsync({
          userId: user?.uid,
          orderInfo: {
            ...currentOrder,
            status: newOrderStatus,
          },
        })
      );
    }
    setOpenOrderInfo(false);
    navigate(`/admin/orders`);
  };

  const moment = require("moment");
  const dueDate = moment(orderInfo?.customerDate);
  const dueFromNow = moment(
    formatDateFromString(orderInfo?.customerDate),
    "MMM DD,YYYY"
  ).fromNow();

  const [editOrderInfo, setNewOrderInfo] = useState(false);

  const Carousel = require("react-responsive-carousel").Carousel;

  return (
    <Box sx={{ display: "flex" }}>
      {view === "calendar" ? (
        <Stack
          flex={1}
          direction="row"
          alignItems="stretch"
          spacing={0.5}
          onClick={handleOpenOrderInfo}
          sx={{
            "&:hover": {
              cursor: "pointer",
              opacity: 1,
              borderRadius: 1,
              outline: "1.5px solid rgba(0,0,0,0.06)",
            },
            opacity:
              orderInfo.status === ORDER_STATUS_COMPLETED ||
              moment(orderInfo.customerDate).isBefore(moment(), "day")
                ? 0.3
                : 1,
          }}
        >
          <Box
            alignSelf="stretch"
            sx={{
              mt: 0,
              mb: 0,
              width: "4px",
              backgroundColor:
                orderInfo.status === ORDER_STATUS_NOTSTARTED
                  ? "#E7E7E0"
                  : orderInfo.status === ORDER_STATUS_INPROGRESS
                  ? "#EEDA8D"
                  : orderInfo.status === ORDER_STATUS_READY
                  ? "#8BA78D"
                  : "#8BA78D",
              borderRadius: 1,
            }}
          />
          <Stack flex={1}>
            {orderInfo.customerFulfillment === "Shipping" ? (
              <LocalShippingRoundedIcon
                fontSize="small"
                sx={{ pr: 0.25, color: "text.secondary" }}
              />
            ) : null}
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontWeight: 600 }}
            >
              {"Order #" + orderInfo?.orderId}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
              }}
            >
              {orderInfo.status === ORDER_STATUS_COMPLETED
                ? "Completed"
                : orderInfo?.firstName +
                  " · " +
                  orderInfo?.count +
                  (orderInfo?.unit == Unit.Dozen ? " dozen" : " cookies")}
            </Typography>
            {orderInfo?.orderTotal - orderInfo?.paid > 0 ? (
              <Typography
                variant="caption"
                color="warning.main"
                sx={{ fontWeight: 600 }}
              >
                Balance: ${orderInfo?.orderTotal - orderInfo?.paid}
              </Typography>
            ) : null}
          </Stack>
        </Stack>
      ) : (
        <ListItem
          onClick={handleOpenOrderInfo}
          sx={{
            p: 0,
            opacity: orderInfo.status === ORDER_STATUS_COMPLETED ? 0.3 : 1,
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: "100%",
              backgroundColor: "white",
              boxShadow: dueDate.isBefore(moment(), "day")
                ? "none"
                : "rgba(0, 0, 0, 0.04) 0px 6px 15px 0px",
              borderRadius: 2,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                alignSelf: "stretch",
                width: "6px",
                backgroundColor:
                  orderInfo.status === ORDER_STATUS_NOTSTARTED
                    ? "#E7E7E0"
                    : orderInfo.status === ORDER_STATUS_INPROGRESS
                    ? "#EEDA8D"
                    : orderInfo.status === ORDER_STATUS_READY
                    ? "#8BA78D"
                    : "#8BA78D",
              }}
            />
            <Stack
              alignItems="center"
              sx={{
                borderRight: "1px solid rgba(0,0,0,0.06)",
                p: 2,
              }}
            >
              <Typography
                variant="caption"
                sx={{ textTransform: "uppercase", fontWeight: 600 }}
              >
                {getMonthFromString(orderInfo?.customerDate)}
              </Typography>
              <Typography variant="body1">
                {getDateFromString(orderInfo?.customerDate)}
              </Typography>
              {orderInfo.customerFulfillment === "Shipping" ? (
                <LocalShippingRoundedIcon
                  fontSize="small"
                  sx={{ pt: 0.25, color: "text.secondary" }}
                />
              ) : null}
              {/* {orderInfo.customerFulfillment === "Pickup" ? (
                <Stack alignItems="center" spacing={-1}>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ fontWeight: 600 }}
                  >
                    00:00
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ fontWeight: 600 }}
                  >
                    am
                  </Typography>
                </Stack>
              ) : null} */}
            </Stack>
            <Stack flex={1}>
              <Stack sx={{ p: 2 }}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    flex={1}
                    variant="caption"
                    color="text.secondary"
                    sx={{ fontWeight: 600 }}
                  >
                    {"Order #" + orderInfo?.orderId}
                  </Typography>
                  {orderInfo?.status === ORDER_STATUS_COMPLETED ? (
                    <Typography
                      variant="caption"
                      color="success.main"
                      sx={{ fontWeight: 600, textTransform: "uppercase" }}
                    >
                      Completed
                    </Typography>
                  ) : dueDate.isAfter(moment()) &&
                    dueDate.diff(moment(), "days") <= 1 ? (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ fontWeight: 600 }}
                    >
                      Due tomorrow
                    </Typography>
                  ) : dueDate.isAfter(moment()) &&
                    dueDate.diff(moment(), "days") > 1 &&
                    dueDate.diff(moment(), "days") <= 7 ? (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ fontWeight: 600 }}
                    >
                      {"Due " + dueFromNow}
                    </Typography>
                  ) : dueDate.isSame(moment(), "day") ? (
                    <Typography
                      variant="caption"
                      color="success.main"
                      sx={{ fontWeight: 600 }}
                    >
                      Due today
                    </Typography>
                  ) : dueDate.isBefore(moment(), "day") ? (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ fontWeight: 600 }}
                    >
                      Past due
                    </Typography>
                  ) : null}
                  {orderInfo?.orderTotal - orderInfo?.paid > 0 ? (
                    <Typography
                      variant="caption"
                      color="warning.main"
                      sx={{ fontWeight: 600 }}
                    >
                      ${orderInfo?.orderTotal - orderInfo?.paid}
                    </Typography>
                  ) : null}
                </Stack>

                <Typography variant="body1" sx={{ fontWeight: 500 }}>
                  {orderInfo?.firstName +
                    " · " +
                    orderInfo?.count +
                    (orderInfo?.unit == Unit.Dozen ? " dozen" : " cookies")}
                </Typography>
              </Stack>

              {!_.isEmpty(orderInfo.notes) ? (
                <div>
                  <Divider light />
                  <Stack sx={{ p: 2 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ whiteSpace: "pre-line" }}
                    >
                      {orderInfo.notes}
                    </Typography>
                  </Stack>
                </div>
              ) : null}
            </Stack>
          </Stack>
        </ListItem>
      )}

      <Dialog
        open={openOrderInfo}
        onClose={handleCloseOrderInfo}
        fullScreen={fullScreen}
        fullWidth
      >
        <DialogContent dividers>
          <Header
            heading={"Order #" + orderInfo.orderId}
            meta={"Created on " + formatDateFromString(orderInfo.orderCreated)}
          >
            {orderInfo.status === ORDER_STATUS_COMPLETED ? (
              <Stack flexDirection="row" alignItems="center" columnGap={1}>
                <CheckCircleIcon color="primary" />
                <Typography
                  variant="button"
                  color="success.main"
                  sx={{ textTransform: "none" }}
                >
                  Completed
                </Typography>
              </Stack>
            ) : (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  handleCloseOrderInfo();
                  navigate(`/admin/orders`);
                  const newOrdersStatus = moveOrder(
                    orderInfo.status,
                    ORDER_STATUS_COMPLETED,
                    ordersStatus.ordersMap[orderInfo.status].indexOf(
                      orderInfo.orderId
                    ) || 0,
                    ordersStatus.ordersMap[ORDER_STATUS_COMPLETED].length || 0
                  );
                  if (user) {
                    updateOrderStatus(
                      user?.uid,
                      orderInfo.orderId,
                      ORDER_STATUS_COMPLETED,
                      newOrdersStatus
                    );
                    dispatch(
                      updateSelectedOrderAsync({
                        userId: user?.uid,
                        orderInfo: {
                          ...orderInfo,
                          status: ORDER_STATUS_COMPLETED,
                        },
                      })
                    );
                  }
                }}
              >
                Mark as completed
              </Button>
            )}
          </Header>

          <ToggleButtonGroup
            value={toggleAlignment}
            exclusive
            onChange={handleProgressChange}
            sx={{ mt: 2, mb: 1, mr: 1 }}
            size="small"
          >
            <ToggleButton
              value={ORDER_STATUS_NOTSTARTED}
              sx={{ textTransform: "none" }}
            >
              Not started
            </ToggleButton>
            <ToggleButton
              value={ORDER_STATUS_INPROGRESS}
              sx={{ textTransform: "none" }}
            >
              In progress
            </ToggleButton>
            <ToggleButton
              value={ORDER_STATUS_READY}
              sx={{
                textTransform: "none",
                backgroundColor:
                  orderInfo.status === ORDER_STATUS_COMPLETED
                    ? "background.default"
                    : "none",
              }}
            >
              Ready
            </ToggleButton>
          </ToggleButtonGroup>

          <TextField
            fullWidth
            multiline
            size="small"
            margin="dense"
            id="orderNotes"
            label="Notes"
            value={notes}
            onChange={(e) => {
              formik.handleChange(e);
              setNotes(e.target.value);
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              if (user) {
                dispatch(
                  updateSelectedOrderAsync({
                    userId: user.uid,
                    orderInfo: { ...currentOrder, notes: notes },
                  })
                );
              }
            }}
          />

          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mt: 1, mb: 0.5, height: "40px" }}
          >
            <Typography variant="h6" flex={1}>
              Order info
            </Typography>
            {editOrderInfo ? null : (
              <Button
                variant="text"
                onClick={() => {
                  setNewOrderInfo(!editOrderInfo);
                }}
              >
                Update
              </Button>
            )}
          </Stack>

          {editOrderInfo ? (
            <Box
              sx={{
                border: "1.5px solid",
                borderColor: "primary.main",
                borderRadius: 2,
                p: 2,
                pt: 1,
              }}
            >
              <NewOrderInfo formik={formik} sellerProfile={sellerProfile} />
              <Stack
                direction="row"
                justifyContent="flex-end"
                spacing={1}
                sx={{ mt: 2 }}
              >
                <ToggleButton
                  size="small"
                  value="check"
                  onClick={() => {
                    formik.resetForm();
                    setNewOrderInfo(false);
                  }}
                >
                  <CloseRoundedIcon />
                </ToggleButton>
                <ToggleButton
                  size="small"
                  value="check"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  <CheckRoundedIcon />
                </ToggleButton>
              </Stack>
            </Box>
          ) : (
            <div>
              <TrackPayment
                total={orderInfo?.orderTotal}
                paid={orderInfo?.paid}
              />
              <Box sx={{ mt: 2 }}>
                <OverviewGrid
                  name={orderInfo?.firstName + " " + orderInfo?.lastName}
                  qty={`${orderInfo?.count}
            ${orderInfo?.unit == Unit.Dozen ? " dozen" : " cookies"}`}
                  date={formatDateFromString(orderInfo?.customerDate)}
                  fulfillment={orderInfo?.customerFulfillment}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <ContactInfo
                  phone={orderInfo?.phoneNumber}
                  email={orderInfo?.email}
                  preferredContact={orderInfo?.preferredContact}
                />
              </Box>
              <Box sx={{ mt: 2.5 }}>
                {orderInfo?.customerFulfillment === "Pickup" ? null : (
                  <ShipTo
                    shippingAddress1={orderInfo?.shippingAddress1}
                    shippingAddress2={orderInfo?.shippingAddress2}
                    shippingCity={orderInfo?.shippingCity}
                    shippingState={orderInfo?.shippingState}
                    shippingZip={orderInfo?.shippingZip}
                  />
                )}
              </Box>
            </div>
          )}

          {orderInfo.requestId ? (
            <Box sx={{ mt: 2 }}>
              <RequestDetails orderInfo={orderInfo} />
            </Box>
          ) : null}

          <Stack direction="row" spacing={3} alignItems="center" sx={{ mt: 3 }}>
            <Button
              variant="text"
              startIcon={<DisabledByDefaultRoundedIcon />}
              sx={{ color: "text.secondary" }}
              onClick={() => {
                navigate(`/admin/orders`);
                const newOrdersStatus = moveOrder(
                  orderInfo.status,
                  ORDER_STATUS_CANCELED,
                  ordersStatus.ordersMap[orderInfo.status].indexOf(
                    orderInfo.orderId
                  ) || 0,
                  ordersStatus.ordersMap[ORDER_STATUS_CANCELED].length || 0
                );
                if (user) {
                  updateOrderStatus(
                    user?.uid,
                    orderInfo.orderId,
                    ORDER_STATUS_CANCELED,
                    newOrdersStatus
                  );
                  dispatch(
                    updateSelectedOrderAsync({
                      userId: user?.uid,
                      orderInfo: {
                        ...orderInfo,
                        status: ORDER_STATUS_CANCELED,
                      },
                    })
                  );
                }
              }}
            >
              Cancel order
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOrderInfo} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
