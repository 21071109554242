import React, { useContext, useState } from "react";
import { bakeLinkSignIn, login } from "Util/firebaseHelper";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  Menu,
  ButtonProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
} from "@mui/material";
import { GoogleSignInButton } from "Components/AllComponents";
import { useSession } from "Context/userAuthContext";
import Logo from "Assets/logo-full.svg";

interface Props extends ButtonProps {
  variant: "text" | "outlined" | "contained" | undefined;
  label: string;
  username: string;
}

export const SignInBtn = ({ variant, label, username, ...props }: Props) => {
  const navigate = useNavigate();
  const { user } = useSession();

  const [anchorElLogin, setAnchorElLogin] = useState<null | HTMLElement>(null);
  const handleOpenLoginMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (!user) {
      setAnchorElLogin(event.currentTarget);
    } else {
      navigate("/admin/home");
    }
  };
  const handleCloseLoginMenu = () => {
    setAnchorElLogin(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {props.size === "large" ? (
        <Button
          disabled={props.disabled}
          variant={variant}
          size="large"
          onClick={handleOpenLoginMenu}
        >
          <Typography variant="h6">{label}</Typography>
        </Button>
      ) : (
        <Button
          disabled={props.disabled}
          variant={variant}
          onClick={handleOpenLoginMenu}
        >
          {label}
        </Button>
      )}
      <Dialog open={Boolean(anchorElLogin)} onClose={handleCloseLoginMenu}>
        <DialogContent>
          <Stack alignItems="center">
            <img src={Logo} alt="Logo" style={{ width: 30, height: 30 }} />
            <Typography variant="h6" sx={{ mt: 1 }}>
              Welcome
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              We'll sign you in or create an account if you don't have one yet.
            </Typography>
            <GoogleSignInButton
              onClick={async () => {
                const user = await login();
                handleCloseLoginMenu();
                await bakeLinkSignIn(user.user.uid, username);
                navigate("/admin/home");
              }}
            />
            <Typography variant="caption" color="text.secondary" sx={{ mt: 2 }}>
              By signing up, I agree to Flowly's{" "}
              <Link
                id="tos"
                href="/terms-of-service"
                target="_blank"
                sx={{ color: "text.secondary" }}
              >
                Terms of Services
              </Link>{" "}
              and{" "}
              <Link
                id="tos"
                href="/privacy-policy"
                target="_blank"
                sx={{ color: "text.secondary" }}
              >
                Privacy Policy
              </Link>
              .
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
