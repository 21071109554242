import { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { Unit } from "Model/CustomerRequestInfo";
import { getMonthFromString, getDateFromString } from "Util/dateUtil";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { formatDateFromString } from "Util/dateUtil";

// ui related
import {
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  Stack,
  Box,
  Tabs,
  Tab,
} from "@mui/material";

//icons
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectedRequestSelector } from "Redux/selectors";
import { selectRequest } from "Redux/actionCreators";
import LocalShippingRoundedIcon from "@mui/icons-material/LocalShippingRounded";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";

export const PendingRequests = () => {
  const { allRequests } = useSession();
  let { requestId } = useParams<"requestId">();

  const theme = useTheme();

  const selectedRequest = useAppSelector(selectedRequestSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const moment = require("moment");

  useEffect(() => {
    const request = allRequests.find((r) => r.requestId === requestId);
    if (request) {
      dispatch(selectRequest(request));
    } else if (allRequests.length > 0) {
      dispatch(selectRequest(allRequests[0]));
    }
  }, [allRequests, requestId]);

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        "& .MuiTabs-indicator": { display: "none" },
        "& .MuiTabs-flexContainer": { pb: 1 },
      }}
    >
      {allRequests.map((request, index) =>
        !moment(request.customerDate).isBefore(moment(), "day") &&
        !request.orderId ? (
          <div key={index}>
            <ListItemButton
              selected
              onClick={() => {
                dispatch(selectRequest(request));
                navigate(`/admin/requests/${request.requestId}`);
              }}
              sx={{
                mr: 1,
                "&.Mui-selected": {
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  boxShadow: "rgba(0, 0, 0, 0.04) 0px 3px 10px 0px",
                  "&:hover": { backgroundColor: "white" },
                },
              }}
            >
              <Stack
                sx={{
                  mr: 2,
                  alignItems: "center",
                  width: 32,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ textTransform: "uppercase", fontWeight: 600 }}
                >
                  {getMonthFromString(request.customerDate).toUpperCase()}
                </Typography>
                <Typography variant="body2">
                  {getDateFromString(request.customerDate)}
                </Typography>
              </Stack>
              <ListItemText
                primary={
                  <Typography flex={1} variant="body2" sx={{ fontWeight: 600 }}>
                    {"Request #" + request.requestId}
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {" - " +
                        request.firstName +
                        " · " +
                        request.count +
                        (request.unit == Unit.Dozen ? " dozen" : " cookies")}
                      {/* (submitted on {formatDateFromString(request.created)}) */}
                    </Typography>
                  </Typography>
                }
                secondary={
                  "Submitted on " + formatDateFromString(request.created)
                }
              />
            </ListItemButton>
          </div>
        ) : null
      )}
    </Tabs>
  );
};
