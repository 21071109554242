import { Button, Stack, Typography } from "@mui/material";
import Logo from "Assets/logo-trans.svg";
import LogoWhite from "Assets/logo-white.svg";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

type Props = {
  color: "dark" | "light";
};

export const CreatedWith = ({ color }: Props) => {
  const textColor = color === "dark" ? "text.primary" : "white";

  return (
    <Stack alignItems="center">
      <Button
        variant="outlined"
        color="secondary"
        startIcon={
          <img
            src={color === "dark" ? Logo : LogoWhite}
            alt="Logo"
            style={{ width: 16 }}
          />
        }
        endIcon={<ArrowForwardRoundedIcon />}
        sx={{ mt: 5 }}
        onClick={() => {
          window.open("/", "_blank");
        }}
      >
        Create a free cookie shop
      </Button>
    </Stack>
  );
};
