import { Stack, Typography } from "@mui/material";

type Props = {
  heading: string;
  meta?: string;
  chip?: React.ReactElement;
  children?: React.ReactNode;
};

export const Header = ({ heading, meta, chip, children }: Props) => {
  return (
    <Stack
      direction={{ md: "row", xs: "column" }}
      spacing={2}
      alignItems="flex-start"
    >
      <Stack flex={1}>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Typography variant="h5">{heading}</Typography>
          {chip && chip}
        </Stack>
        {meta ? (
          <Typography variant="body2" color="text.secondary">
            {meta}
          </Typography>
        ) : null}
      </Stack>

      {children ? <div>{children}</div> : null}
    </Stack>
  );
};
