import React, { useState, useEffect } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { columnOrder } from "Model/OrdersStatus";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  ordersSelector,
  ordersStatusSelector,
  selectPendingRequests,
} from "Redux/selectors";
import { updateOrderStatusAsync } from "Redux/Reducers/ordersStatus";
import { defaultOrderInfo, OrderInfo } from "Model/OrderInfo";

// ui related
import {
  Button,
  Box,
  Typography,
  CssBaseline,
  Stack,
  Divider,
  Menu,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { AppNav } from "Components/AllComponents";
import { OrderColumn } from "./Kanban/OrderColumn";
import { CreateOrder } from "Pages/BusinessView/AllOrders/Components/CreateOrder";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// icons
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Calendar from "./Calendar/Calendar";
import { Availability } from "Components/BusinessView/Availability";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import ViewKanbanOutlinedIcon from "@mui/icons-material/ViewKanbanOutlined";
import { PendingRequests } from "./Components/PendingRequests";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";

const AllOrders = () => {
  const { user, sellerProfile, allRequests } = useSession();
  const allOrders = useAppSelector(ordersSelector);
  const pendingRequests = useAppSelector(selectPendingRequests);
  const formPublished = sellerProfile.forms && sellerProfile.forms.length > 0;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // redux status is immutable, need to clone so that start and finish array can be modified. Clone is not good enough.
  const ordersStatus = useAppSelector(ordersStatusSelector);
  const [mobileOpen, setMobileOpen] = useState(false);

  const [localOrderStatus, setLocalOrderStatus] = useState(ordersStatus);
  const getOrderFromId = (orderId: string) => {
    return allOrders.find((i) => i.orderId === orderId);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    setLocalOrderStatus(ordersStatus);
  }, [ordersStatus]);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const onDragEnd = ({
    destination,
    source,
    draggableId, // the order id selected
    type,
  }: DropResult) => {
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = _.clone(localOrderStatus.ordersMap[source.droppableId]);
    const finish = _.clone(localOrderStatus.ordersMap[destination.droppableId]);
    console.log("ssss", localOrderStatus, start, finish, source, destination);
    const oldOrderInfo = getOrderFromId(draggableId) || defaultOrderInfo;
    const newOrderInfo = {
      ...oldOrderInfo,
      status: destination.droppableId,
    } as OrderInfo;
    if (source.droppableId === destination.droppableId) {
      console.log("same list");

      start.splice(source.index, 1);
      start.splice(destination.index, 0, draggableId);

      const newState = {
        ...localOrderStatus,
        ordersMap: {
          ...localOrderStatus.ordersMap,
          [source.droppableId]: start,
        },
      };

      if (user) {
        setLocalOrderStatus(newState);
        dispatch(
          updateOrderStatusAsync({
            userId: user.uid,
            orderInfo: newOrderInfo,
            orderStatus: destination.droppableId,
            ordersStatus: newState,
          })
        );
      }
      return;
    }
    // Moving from one list to another
    start.splice(source.index, 1);
    finish.splice(destination.index, 0, draggableId);

    const newState = {
      ...localOrderStatus,
      ordersMap: {
        ...localOrderStatus.ordersMap,
        [source.droppableId]: start,
        [destination.droppableId]: finish,
      },
    };
    if (user) {
      setLocalOrderStatus(newState);
      dispatch(
        updateOrderStatusAsync({
          userId: user.uid,
          orderInfo: newOrderInfo,
          orderStatus: destination.droppableId,
          ordersStatus: newState,
        })
      );
    }
  };

  const [anchorMoreMenu, setAnchorMoreMenu] = useState<null | HTMLElement>(
    null
  );
  const handleOpenMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMoreMenu(event.currentTarget);
  };
  const handleCloseMoreMenu = () => {
    setAnchorMoreMenu(null);
  };

  const [view, setView] = useState("calendar");
  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null
  ) => {
    if (newView !== null) {
      setView(newView);
    } else {
      return;
    }
  };

  return (
    <Box sx={{ display: "flex" }} className="non-selectable">
      <CssBaseline />
      <AppNav
        required={false}
        drawerName="Orders"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />

      <Box
        component="main"
        sx={{
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: "100%",
          backgroundColor: "background.default",
        }}
      >
        <Stack flex={1} sx={{ mb: 4 }}>
          <Stack
            direction="row"
            alignItems="center"
            columnGap={2}
            sx={{ mb: 1 }}
          >
            <Typography variant="h5" flex={1}>
              Pending requests
            </Typography>
            {smallScreen ? (
              <Availability>
                <Button color="secondary" size="small" variant="outlined">
                  Update availability
                </Button>
              </Availability>
            ) : null}
          </Stack>

          {!formPublished ? (
            <Stack spacing={1}>
              <Typography variant="body1">
                Create a custom order form to receive requests.
              </Typography>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/admin/form");
                }}
                sx={{ width: "fit-content" }}
              >
                Create form
              </Button>
            </Stack>
          ) : allRequests.length === 0 ? (
            <Typography variant="body2" color="text.secondary">
              You don't have any requests at the moment. You will receive an
              email once a new request is submitted.
            </Typography>
          ) : pendingRequests.length > 0 ? (
            <PendingRequests />
          ) : (
            <Typography variant="body2" color="text.secondary">
              You have no pending requests to be converted into orders at the
              moment.
            </Typography>
          )}

          {allRequests.length > 0 && (
            <Button
              variant="outlined"
              size="small"
              endIcon={<ChevronRightRoundedIcon />}
              onClick={() => {
                navigate("/admin/requests");
              }}
              sx={{ width: "fit-content", mt: 1 }}
            >
              All requests
            </Button>
          )}
        </Stack>

        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
          <Stack direction="row" alignItems="center" columnGap={2} flex={1}>
            <Typography variant="h5">Orders</Typography>
          </Stack>
          <ToggleButtonGroup size="small" exclusive value={view}>
            <ToggleButton value="calendar" onClick={handleViewChange}>
              <CalendarViewMonthIcon />
            </ToggleButton>
            <ToggleButton value="kanban" onClick={handleViewChange}>
              <ViewKanbanOutlinedIcon />
            </ToggleButton>
            <ToggleButton value="" onClick={handleOpenMoreMenu}>
              <MoreHorizIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorMoreMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorMoreMenu)}
          onClose={handleCloseMoreMenu}
        >
          <CreateOrder onClickAction={handleCloseMoreMenu} />
          <Divider sx={{ mt: 1, mb: 1 }} />
          <MenuItem
            onClick={() => {
              navigate("/admin/completed-orders");
            }}
          >
            <Typography textAlign="center">Completed orders</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigate("/admin/canceled-orders");
            }}
          >
            <Typography textAlign="center">Canceled orders</Typography>
          </MenuItem>
        </Menu>

        {view === "calendar" ? (
          <Calendar />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={3}
              flex={1}
              sx={{ width: "100%" }}
            >
              {columnOrder.map((columnId) => {
                const orders = localOrderStatus.ordersMap[columnId.name];
                return (
                  <OrderColumn
                    orderIds={orders}
                    key={columnId.name}
                    title={columnId.name}
                    id={columnId.name}
                    showCount={columnId.showCount}
                    collapsible={smallScreen}
                  />
                );
              })}
            </Stack>
          </DragDropContext>
        )}
      </Box>
    </Box>
  );
};

export default AllOrders;
