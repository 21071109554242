import { ORDER_STATUS_CANCELED, ORDER_STATUS_COMPLETED } from "Constants";
import { RootState } from "./store";
import { createSelector } from "@reduxjs/toolkit";
import { PresaleOrder } from "Model/PresaleOrder";
import { Fulfillment, generateFulfillmentKey } from "Model/PresaleInfo";
import { OrderInfo } from "Model/OrderInfo";
import { getDateMMDDYYYY } from "Util/dateUtil";
import moment from "moment";
import { Customer } from "Model/Customer";
import { subscribersSlice } from "./Reducers/subscribers";
import _ from "lodash";

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const ordersSelector = (state: RootState) => state.orders;
export const completedOrdersSelector = (state: RootState) =>
  state.ordersStatus.ordersMap[ORDER_STATUS_COMPLETED];
export const canceledOrdersSelector = (state: RootState) =>
  state.ordersStatus.ordersMap[ORDER_STATUS_CANCELED];

export const ordersByCustomerDateSelector = createSelector(
  (state: RootState) => state.orders,
  (orders) => {
    const filteredOrders = orders.filter(
      (order) => order.status !== ORDER_STATUS_CANCELED
    );

    const ordersMap = filteredOrders.reduce<Map<string, OrderInfo[]>>(
      (map, order) => {
        const customerDate = getDateMMDDYYYY(new Date(order.customerDate));
        if (!map.has(customerDate)) {
          map.set(customerDate, []);
        }
        map.get(customerDate)!.push(order);
        return map;
      },
      new Map<string, OrderInfo[]>()
    );

    return ordersMap;
  }
);

export const selectedOrderSelector = (state: RootState) => state.selectedOrder;
export const selectedRequestSelector = (state: RootState) =>
  state.selectedRequest;
export const businessInfoSelector = (state: RootState) => state.businessInfo;
export const sellerProfileSelector = (state: RootState) => state.sellerProfile;
export const ordersStatusSelector = (state: RootState) => state.ordersStatus;
export const presalesSelector = (state: RootState) => state.presales.data;
export const presalesLoadingSelector = (state: RootState) =>
  state.presales.loading;
export const selectedPresaleSelector = (state: RootState) =>
  state.selectedPresale;
export const selectPresaleById = createSelector(
  [presalesSelector, (_: RootState, presaleId: string) => presaleId],
  (presales, presaleId) =>
    presales.find((presale) => presale.presaleId === presaleId)
);
export const selectCart = (state: RootState) => state.cart;
export const selectCartItems = (state: RootState) => state.cart.items;
// use createSelector to do memorization
export const selectCartItemById = createSelector(
  [selectCartItems, (_, itemId: string) => itemId],
  (items, itemId) => items[itemId]
);

export const selectAllRequests = (state: RootState) => state.requests.requests;

export const selectPendingRequests = createSelector(
  selectAllRequests,
  (requests) => {
    // Filter requests where orderId is falsy or undefined
    return requests.filter(
      (request) =>
        !request.orderId &&
        !moment(request.customerDate).isBefore(moment(), "day")
    );
  }
);

// Selector to get all orders
export const selectAllPresaleOrders = (state: RootState) =>
  state.presaleOrders.orders;

export const selectPresaleOrdersLoading = (state: RootState) =>
  state.presaleOrders.loading;

// Selector to get unpaid orders
export const selectUnpaidPresaleOrders = createSelector(
  selectAllPresaleOrders,
  (orders) =>
    Object.keys(orders).reduce((unpaidOrders, presaleId) => {
      unpaidOrders[presaleId] = orders[presaleId].filter(
        (order: PresaleOrder) => !order.paid
      );
      return unpaidOrders;
    }, {} as Record<string, PresaleOrder[]>)
);

// Selector to get all possible fulfillments sorted by order
export const selectAllPresaleFulfillments = createSelector(
  selectAllPresaleOrders,
  (presaleOrders) => {
    // Create a Set to store unique fulfillments
    const uniqueFulfillments = new Set();

    // Loop through each presale order and add the fulfillments to the Set
    Object.values(presaleOrders).forEach((orders) => {
      orders.forEach((order) => {
        uniqueFulfillments.add(order.fulfillment);
      });
    });

    // Convert the Set to an array and sort it in ascending order
    const sortedFulfillments = Array.from(uniqueFulfillments).sort();

    return sortedFulfillments;
  }
);

// Selector to aggregate orders based on fulfillment and presaleId
export const selectOrdersByFulfillments = createSelector(
  (state: RootState) => state.presaleOrders.orders ?? {},
  (presaleOrders: Record<string, PresaleOrder[]>) => {
    // Create an object to store aggregated orders by fulfillment and presaleId
    const aggregatedOrders: Record<string, Record<string, PresaleOrder[]>> = {};

    // Loop through each presale order and aggregate orders by fulfillment and presaleId
    Object.entries(presaleOrders).forEach(([presaleId, orders]) => {
      orders.forEach((order) => {
        const fulfillmentKey = generateFulfillmentKey(order.fulfillment);

        if (!aggregatedOrders[presaleId]) {
          aggregatedOrders[presaleId] = {};
        }
        if (!aggregatedOrders[presaleId][fulfillmentKey]) {
          aggregatedOrders[presaleId][fulfillmentKey] = [];
        }
        aggregatedOrders[presaleId][fulfillmentKey].push(order);
      });
    });

    // Sort the aggregated orders by fulfillment in ascending order
    Object.keys(aggregatedOrders).forEach((presaleId) => {
      const sortedAggregatedOrders: Record<string, PresaleOrder[]> = {};
      Object.keys(aggregatedOrders[presaleId])
        .sort((a, b) => {
          const [aDate, aTime, aAddress] = a.split("##");
          const [bDate, bTime, bAddress] = b.split("##");
          if (aDate !== bDate) {
            return Date.parse(aDate) - Date.parse(bDate);
          } else if (aTime !== bTime) {
            return aTime.localeCompare(bTime);
          } else {
            return aAddress.localeCompare(bAddress);
          }
        })
        .forEach((fulfillment) => {
          sortedAggregatedOrders[fulfillment] =
            aggregatedOrders[presaleId][fulfillment];
        });
      aggregatedOrders[presaleId] = sortedAggregatedOrders;
    });

    return aggregatedOrders;
  }
);

export const customersSelector = createSelector(
  [ordersSelector, selectAllPresaleOrders],
  (orders, presaleOrders) => {
    // Create a map of customer details
    const customerDetails: Record<string, Customer> = {};

    // Count orders for each customer
    orders.forEach((order) => {
      const email = order.email;
      if (!customerDetails[email]) {
        customerDetails[email] = {
          id: email,
          email: email,
          phoneNumber: order.phoneNumber,
          name: `${order.firstName} ${order.lastName}`,
          ordersCount: 0,
          presaleOrdersCount: 0,
          subscribed: false,
        };
      }
      customerDetails[email].ordersCount += 1;
    });

    // Count presale orders for each customer
    Object.entries(presaleOrders).forEach(([presaleId, orders]) => {
      orders.forEach((order) => {
        const email = order.email;
        if (!customerDetails[email]) {
          customerDetails[email] = {
            id: email,
            email: email,
            phoneNumber: order.phoneNumber,
            name: `${order.firstName} ${order.lastName}`,
            ordersCount: 0,
            presaleOrdersCount: 0,
            subscribed: false,
          };
        }
        customerDetails[email].presaleOrdersCount += 1;
      });
    });

    // Convert the details object to an array
    return Object.values(customerDetails);
  }
);

export const subscribersSelector = (state: RootState) => state.subscribers;

export const customersAndSubscribersSelector = createSelector(
  [customersSelector, subscribersSelector],
  (customers, subscribers) => {
    const joinedList = _.cloneDeep(customers);
    subscribers.forEach((subscriber) => {
      const found = joinedList.find(
        (customer) => customer.email === subscriber
      );
      if (!found) {
        joinedList.push({
          email: subscriber,
          id: subscriber,
          name: "",
          phoneNumber: "",
          ordersCount: 0,
          presaleOrdersCount: 0,
          subscribed: true,
        });
      } else {
        found.subscribed = true;
      }
    });
    return joinedList;
  }
);
