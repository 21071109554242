import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "Context/userAuthContext";
import { Unit } from "Model/CustomerRequestInfo";
import { formatDateFromString } from "Util/dateUtil";
import { submitNewOrderTransaction } from "Util/firebaseHelper";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { businessInfoSelector, selectedRequestSelector } from "Redux/selectors";
import useMediaQuery from "@mui/material/useMediaQuery";

// ui related
import {
  Box,
  CssBaseline,
  Button,
  Typography,
  Drawer,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Alert,
  Popover,
} from "@mui/material";
import {
  AppNav,
  SubNav,
  InfoBox,
  CardStyle,
  ContentWrapNarrow,
} from "Components/AllComponents";
import { NewOrderInfo } from "Pages/BusinessView/AllOrders/Components/NewOrderInfo";
import { RequestsDrawer } from "./RequestsDrawer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";
import { useTheme } from "@mui/material/styles";
import { OverviewGrid } from "Components/BusinessView/CustomOrder/OverviewGrid";
import { ContactInfo } from "Components/BusinessView/CustomOrder/ContactInfo";

// icons
import { DEFAULT_BUCKET_LIST } from "Constants";
import { useFormik } from "formik";
import { defaultOrderInfo, orderInfoValidationSchema } from "Model/OrderInfo";
import { convertRequestToOrder } from "Util/helpers";
import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";
import { deleteRequestAsync } from "Redux/Reducers/requests";
import { LabeledText } from "Components/LabeledText";
import { Header } from "Components/Header";
import { ShipTo } from "Components/BusinessView/CustomOrder/ShipTo";

const drawerWidth = 260;
interface Props {
  window?: () => Window;
}

const AllRequests = (props: Props) => {
  const navigate = useNavigate();
  const businessInfo = useAppSelector(businessInfoSelector);
  const { user, sellerProfile, allRequests } = useSession();
  const moment = require("moment");
  const dispatch = useAppDispatch();
  const selectedRequest = useAppSelector(selectedRequestSelector);

  const formPublished = sellerProfile.forms && sellerProfile.forms.length > 0;

  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //design inspirations
  const Carousel = require("react-responsive-carousel").Carousel;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: selectedRequest
      ? convertRequestToOrder(businessInfo.businessInfo.id, selectedRequest)
      : defaultOrderInfo,
    validationSchema: orderInfoValidationSchema(
      sellerProfile.payments.length > 0
    ),
    onSubmit: async (values, { resetForm }) => {
      if (user?.uid) {
        submitNewOrderTransaction(
          user.uid,
          businessInfo.businessInfo.id,
          values,
          true,
          sellerProfile
        );
        setOpenNewOrder(false);
      }
    },
  });

  const [openNewOrder, setOpenNewOrder] = useState(false);
  const handleOpenNewOrder = () => {
    setOpenNewOrder(true);
  };
  const handleCloseNewOrder = () => {
    setOpenNewOrder(false);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeleteRequest = Boolean(anchorEl);

  return !formPublished ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      <AppNav
        required={allRequests.length !== 0}
        drawerName="Requests"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />
      <SubNav
        returnTo="/admin/orders"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
        backBtn="Back"
      />
      <Box component="main" sx={{ p: { xs: 2, sm: 3 }, mt: 6, width: "100%" }}>
        <CardStyle>
          <Stack alignItems="center" spacing={2}>
            <Typography variant="subtitle2">
              Create a custom order form to receive requests.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/admin/form");
              }}
            >
              Create form
            </Button>
          </Stack>
        </CardStyle>
      </Box>
    </Box>
  ) : allRequests.length === 0 ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      <AppNav
        required={allRequests.length !== 0}
        drawerName="Requests"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />
      <Box component="main" sx={{ p: { xs: 2, sm: 3 }, mt: 6, width: "100%" }}>
        <CardStyle>
          <Typography variant="subtitle2">
            You don't have any requests at the moment. You will receive an email
            once a new request is submitted.
          </Typography>
        </CardStyle>
      </Box>
    </Box>
  ) : selectedRequest.requestId === "" ? (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* ---------- app bar (no request) ---------- */}
      <AppNav
        required={allRequests.length !== 0}
        drawerName="Requests"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
      />

      {/* ---------- drawer (no request) ---------- */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        {/* ---------- mobile drawer (no request) ---------- */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
        {/* ---------- desktop drawer (no request) ---------- */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
          open
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
      </Box>

      {/* ---------- request content (no request) ---------- */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: {
            md: `calc(100% - ${(<RequestsDrawer />)}px)`,
          },
          minHeight: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <ContentWrapNarrow>
          <CardStyle>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="flex-start"
            >
              <Stack flexGrow={1}>
                <Typography variant="h5">
                  {!user ? "Please sign in" : "Request doesn't exist"}
                </Typography>
              </Stack>
            </Stack>
          </CardStyle>
        </ContentWrapNarrow>
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* ---------- app bar ---------- */}
      <SubNav
        drawerName="Requests"
        returnTo="/admin/orders"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
        backBtn="Back"
      />

      {/* ---------- drawer ---------- */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        {/* ---------- mobile drawer ---------- */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
        {/* ---------- desktop drawer ---------- */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
          open
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <RequestsDrawer />
          </Box>
        </Drawer>
      </Box>

      {/* ---------- request content ---------- */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: {
            md: `calc(100% - ${(<RequestsDrawer />)}px)`,
          },
          minHeight: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <ContentWrapNarrow>
          <Stack spacing={2}>
            <CardStyle sx={{ flex: 1 }}>
              {moment(selectedRequest.customerDate).isBefore(
                moment(),
                "day"
              ) ? (
                <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
                  This request will be automatically deleted on{" "}
                  {formatDateFromString(
                    moment(selectedRequest.customerDate).add(30, "day")
                  )}{" "}
                  (30 days after the due date).
                </Alert>
              ) : null}

              <Header
                heading={"Request #" + selectedRequest.requestId}
                meta={
                  "Submitted on " +
                  formatDateFromString(selectedRequest.created)
                }
              >
                <Box>
                  {!!selectedRequest.orderId ? (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        navigate(`/admin/orders/${selectedRequest.orderId}`);
                      }}
                    >
                      View order
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleOpenNewOrder();
                      }}
                    >
                      Create order
                    </Button>
                  )}
                </Box>
              </Header>
              <Dialog
                open={openNewOrder}
                onClose={handleCloseNewOrder}
                fullScreen={fullScreen}
                fullWidth={true}
              >
                <DialogTitle>New order</DialogTitle>
                <DialogContent dividers>
                  <InfoBox>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <ForwardToInboxRoundedIcon />
                      <Typography>
                        Once the order is created, a confirmation email will be
                        sent to your customer.
                      </Typography>
                    </Stack>
                  </InfoBox>
                  <NewOrderInfo formik={formik} sellerProfile={sellerProfile} />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setOpenNewOrder(false);
                    }}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  >
                    Create order
                  </Button>
                </DialogActions>
              </Dialog>

              <Box sx={{ mt: 2 }}>
                <OverviewGrid
                  name={
                    selectedRequest.firstName + " " + selectedRequest.lastName
                  }
                  qty={`${selectedRequest.count}
              ${selectedRequest.unit == Unit.Dozen ? " dozen" : " cookies"}`}
                  date={formatDateFromString(selectedRequest.customerDate)}
                  fulfillment={selectedRequest.customerFulfillment}
                />
              </Box>
            </CardStyle>

            <CardStyle>
              <ContactInfo
                phone={selectedRequest.phoneNumber}
                email={selectedRequest.email}
                preferredContact={selectedRequest.preferredContact}
              />
            </CardStyle>

            {selectedRequest.customerFulfillment === "Shipping" ? (
              <CardStyle>
                <ShipTo
                  shippingAddress1={selectedRequest.shippingAddress1}
                  shippingAddress2={selectedRequest.shippingAddress2}
                  shippingCity={selectedRequest.shippingCity}
                  shippingState={selectedRequest.shippingState}
                  shippingZip={selectedRequest.shippingZip}
                />
              </CardStyle>
            ) : null}

            <CardStyle sx={{ flex: 1, mt: 3 }}>
              <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
                Details
              </Typography>
              <Stack spacing={2}>
                {selectedRequest.bucketLists === undefined ||
                selectedRequest.bucketLists === DEFAULT_BUCKET_LIST ? null : (
                  <LabeledText
                    label="Bucket list item"
                    text={selectedRequest.bucketLists}
                  />
                )}
                {selectedRequest.event ? (
                  <LabeledText label="Event" text={selectedRequest.event} />
                ) : null}
                {selectedRequest.theme ? (
                  <LabeledText label="Theme" text={selectedRequest.theme} />
                ) : null}
                {selectedRequest.colors ? (
                  <LabeledText label="Colors" text={selectedRequest.colors} />
                ) : null}
                {selectedRequest.personalization ? (
                  <LabeledText
                    label="Personalization/other requirements"
                    text={selectedRequest.personalization}
                    keepTextFormatting
                  />
                ) : null}
                {selectedRequest.flavors?.length > 0 ? (
                  <LabeledText
                    label="Flavor"
                    text={selectedRequest.flavors[0]}
                  />
                ) : null}
                {selectedRequest.packagings?.length > 0 ? (
                  <LabeledText
                    label="Packaging"
                    text={selectedRequest.packagings[0]}
                  />
                ) : null}
                {selectedRequest.questions ? (
                  <LabeledText
                    label="Questions/comments"
                    text={selectedRequest.questions}
                    keepTextFormatting
                  />
                ) : null}
              </Stack>
            </CardStyle>

            {selectedRequest.designInspirations?.length ? (
              <CardStyle>
                <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
                  Design inspirations
                </Typography>
                <Carousel
                  showThumbs={false}
                  autoPlay={false}
                  infiniteLoop={true}
                  dynamicHeight={true}
                  showStatus={selectedRequest.designInspirations.length >= 2}
                >
                  {selectedRequest.designInspirations.map(
                    (image, index): any => (
                      <img key={index} src={image} />
                    )
                  )}
                </Carousel>
              </CardStyle>
            ) : null}

            <Box sx={{ pt: 1 }}>
              <Button
                variant="text"
                onClick={handleClickPopover}
                sx={{
                  width: "fit-content",
                  color: "text.secondary",
                }}
              >
                Delete request
              </Button>
              <Popover
                id="deletePresale"
                open={openDeleteRequest}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Typography sx={{ mb: 1, maxWidth: "400px" }}>
                    Are you sure you want to delete this request?
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button variant="outlined" onClick={handleClosePopover}>
                      Close
                    </Button>
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => {
                        if (user) {
                          handleClosePopover();
                          dispatch(
                            deleteRequestAsync({
                              formId: businessInfo.businessInfo.id,
                              requestId: selectedRequest.requestId,
                            })
                          );
                          navigate("/admin/requests");
                        }
                      }}
                    >
                      Delete request
                    </Button>
                  </Stack>
                </Box>
              </Popover>
            </Box>
          </Stack>
        </ContentWrapNarrow>
      </Box>
    </Box>
  );
};

export default AllRequests;
